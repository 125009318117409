export const mainRouting = [
  [
    {
      name: "Belts",
      img: "Martial Arts/Belts/Belts_img_233.jpg",
      desc: `<p>
      
      Belts: with rank tags 100% cotton 45mm wide 9 rows of stitching for double strength making them very durable, 100mm wide black color round stripe on one end, high quality construction.

      <ul>
      <li>
      Made from 100% cotton
      </li>
      <li>
      Features 9 rows of stitching, very durable
      </li>
      <li>
      100mm wide
      </li>
      <li>
      Easy to tie
      </li>
      <li>
      Sizing Guide
      </li>
      </ul>


Available sizes: A1 = 250cm, A2 = 280cm, A3 = 300cm, A4 = 320cm

      </p>`,
      furtherObj: [
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_233.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_234.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_235.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_236.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_237.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_238.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_239.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_240.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_241.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_242.jpg",
        },
        {
          name: "Belts",
          img: "Martial Arts/Belts/Belts_img_243.jpg",
        },
      ],
    },
    {
      name: "Hapkido",
      img: "Martial Arts/hapkido/Hapkido20Gi_img_425.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Made from 100% cotton
      </li>
      <li>
      Features generous cuts throughout, maximum comfort and unrestricted movements
      </li>
      <li>
      Diamond embroidered chain stitched all across the jacket
      </li>
      <li>
      Trouser has an elasticated waistband cord
      </li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi_img_425.jpg",
        },
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi_img_426.jpg",
        },
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi_img_427.jpg",
        },
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi-_img_428.jpg",
        },
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi_img_429.jpg",
        },
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi_img_430.jpg",
        },
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi_img_431.jpg",
        },
        {
          name: "Hapkido",
          img: "Martial Arts/hapkido/Hapkido20Gi_img_432.jpg",
        },
      ],
    },
    {
      name: "Jiu jitsu gi kimono",
      img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_201.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Pearl weave cotton jacket
      </li>
      <li>
      Jacket 350 gsm with rounded, reinforced slots
      </li>
      <li>
      EVA foam soft collar
      </li>
      <li>
      Pants 230 gsm 100% cotton with reinforced stitching inside leg and around ankle circumfrence
      </li>
      <li>
      PDoes not include belt
      </li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_201.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_202.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_203.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_204.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_205.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_206.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_207.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_208.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_209.jpg",
        },
        {
          name: "Jiu jitsu gi kimono",
          img: "Martial Arts/jiu jitsu gi kimono/jiu20jitsu20gi20kimono_img_210.jpg",
        },
      ],
    },
    {
      name: "Judo",
      img: "Martial Arts/judo/Judo20uniform_img_399.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Custom logo can place on the uniform
      </li>
      <li>
      Our uniforms are available in size
      </li>
      <li>
      Includes jacket and pants. Belt NOT included
      </li>
      <li>
      Also available in customize colors & sizes
      </li>
      <li>
      Features:
      <ul>
      <li>Double Weave</li>
      <li>Pre-Shrunk Superior Quality Cotton</li>
      <li>Meets IJF* Size and Weight Specifications</li>
      </ul>
      </li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_399.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_400.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_401.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_402.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_403.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_404.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_405.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_406.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_407.jpg",
        },
        {
          name: "Judo",
          img: "Martial Arts/judo/Judo20uniform_img_408.jpg",
        },
      ],
    },
    {
      name: "Kendo uniform",
      img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_217.jpg",
      desc: `<p>
      
      <ul>
      <li>
      HIGHEST-STANDARD WORKMANSHIP - With Japanese traditional workmanship and high quality Cotton , they're great for sensitive skin.
      </li>
      <li>
      PHYSICAL AND MENTAL BENEFITS - The physical benefits of training Kendo as improved strength, endurance and overall fitness are well known in Japan. But a more important benefit when practising Kendo is the mental training that is a natural part of Kendo.
      </li>
      <li>
      IMPROVE YOURSELF
      </li>
      <li>
      LIGHTWEIGHT AND EASY TO CARE FOR - The Kendogi jacket is lightweight and easy to care for. Made of cotton, effective in reducing heat and sweat and comfortable on even the most sensitive skin.
      </li>
      <li>
      COMFORTABLE - They are soft and elegant. In Kendo practicing, the uniform makes the practitioner feeling comfortable, meeting the requirements of Kendo practicing.
      </li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_217.jpg",
        },
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_218.jpg",
        },
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_219.jpg",
        },
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_220.jpg",
        },
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_221.jpg",
        },
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_222.jpg",
        },
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_223.jpg",
        },
        {
          name: "Kendo uniform",
          img: "Martial Arts/kendo uniform/Kendo20Uniforms_img_224.jpg",
        },
      ],
    },
    {
      name: "Kerate uniform",
      img: "Martial Arts/Kerate uniform/Karate20Uniforms_img_185.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Customized Karate Uniform made of 100% Cotton drill cloth (Available in poly65 % , 35% cotton), elastic waist pant with additional drawstring and belt available in all sizes and all custom colors or fabrics on demand.      </li>
      <li>
      Customize Sizes & Colors available      </li>
      <li>
      Custom Logo can place on the uniform      </li>
      
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Kerate uniform",
          img: "Martial Arts/Kerate uniform/Karate20Uniforms_img_185.jpg",
        },
        {
          name: "Kerate uniform",
          img: "Martial Arts/Kerate uniform/Karate20Uniforms_img_186.jpg",
        },
        {
          name: "Kerate uniform",
          img: "Martial Arts/Kerate uniform/Karate20Uniforms_img_187.jpg",
        },
        {
          name: "Kerate uniform",
          img: "Martial Arts/Kerate uniform/Karate20Uniforms_img_188.jpg",
        },
        {
          name: "Kerate uniform",
          img: "Martial Arts/Kerate uniform/Karate20Uniforms_img_189.jpg",
        },
        {
          name: "Kerate uniform",
          img: "Martial Arts/Kerate uniform/Karate20Uniforms_img_190.jpg",
        },
      ],
    },
    {
      name: "Kickboxing",
      img: "Martial Arts/kickboxing/Kickboxing20Gi_img_417.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Used for training and competitions      </li>
      <li>
      Light and easy to wear      </li>
      <li>
      Made from 100% polyester      </li>
      <li>
      Trouser has an elasticated waistband cord      </li>
      <li>
      Can be used for a range of disciplines      </li>
      <li>
      Provides maximum comfort and unrestricted movements     </li>
      <li>
      Customize sizes & customize colors     </li>
      <li>
      Customize logo can place on the suits     </li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_417.jpg",
        },
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_418.jpg",
        },
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_419.jpg",
        },
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_420.jpg",
        },
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_421.jpg",
        },
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_422.jpg",
        },
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_423.jpg",
        },
        {
          name: "Kickboxing",
          img: "Martial Arts/kickboxing/Kickboxing20Gi_img_424.jpg",
        },
      ],
    },
    {
      name: "Kung fu uniform",
      img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_389.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Made of 7.25 oz cotton/polyester top features rollback cuffs, frog buttons    </li>
      <li>
      Kung Fu Uniform Top Colors:
      
      <ul>
      <li>Black with Black Frog Buttons & Black Rollback Cuffs</li>
      <li>Black with Red Frog Buttons & Red Rollback Cuffs</li>
      <li>Black with White Frog Buttons & White Rollback Cuffs</li>
      </ul>
         </li>
      <li>
      Customize colors are available</li>
      <li>
      Customize sizes are available   </li>
      <li>
      Custom logo can place on the uniform   </li>
      <li>
      Sizes: 0 - 8 - Price Varies by size and color  </li>
      <li>
      Brand: Customize   </li>
      <li>
      Kung Fu uniform - Uniform Top Size Chart  </li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_389.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_390.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_391.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_392.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_393.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_394.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_395.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_396.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_397.jpg",
        },
        {
          name: "Kung fu uniform",
          img: "Martial Arts/kung fu uniform/Kung20Fu20Uniforms_img_398.jpg",
        },
      ],
    },
    {
      name: "Ninja",
      img: "Martial Arts/ninga/Ninja20Gi_img_433.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Traditional style Ninja uniform  </li>
      <li>
      Excellent value for money
      
      
         </li>
      <li>
      Jacket features hidden pockets</li>
      <li>
      Made from top quality 100% cotton </li>
      <li>
      Trouser has a waistband cord and calf and ankle ties</li>
      <li>
      Sizes: 0 - 8 - Price Varies by size and color  </li>
      <li>
      Brand: Customize   </li>
      <li>
      Used by students, instructors and suitable for fancy dress parties </li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Ninja",
          img: "Martial Arts/ninga/Ninja20Gi_img_433.jpg",
        },
        {
          name: "Ninja",
          img: "Martial Arts/ninga/Ninja20Gi_img_434.jpg",
        },
        {
          name: "Ninja",
          img: "Martial Arts/ninga/Ninja20Gi_img_435.jpg",
        },
        {
          name: "Ninja",
          img: "Martial Arts/ninga/Ninja20Gi_img_436.jpg",
        },
        {
          name: "Ninja",
          img: "Martial Arts/ninga/Ninja20Gi_img_437.jpg",
        },
        {
          name: "Ninja",
          img: "Martial Arts/ninga/Ninja20Gi_img_438.jpg",
        },
      ],
    },
    {
      name: "Taewondo gi",
      img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_409.jpg",
      desc: `<p>
      
      <ul>
      <li>
      Popular choice of club instructors</li>
      <li>
      Made from Pre-shrunk poly/cotton
      
      
         </li>
      <li>
      Taekwondo printing on the back jacket</li>
      <li>
      Features Custom logo embroidered on jacket and printed on the side of the legs</li>
      <li>
      Inner jacket has adjustable Velcro closure</li>
      <li>
      Comes without belt </li>
      <li>
      Trousers have elastic/cord waist</li>
      
      </ul>


      </p>`,
      furtherObj: [
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_409.jpg",
        },
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_410.jpg",
        },
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_411.jpg",
        },
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_412.jpg",
        },
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_413.jpg",
        },
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_414.jpg",
        },
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_415.jpg",
        },
        {
          name: "Taewondo gi",
          img: "Martial Arts/taewondo gi/Taekwondo20Gi_img_416.jpg",
        },
      ],
    },
  ],
  [
    {
      name: "American Football",
      img: "Sports Wear/American Football/AF1.jpg",
      desc: `
      <p>
      
      
      The American jersey features a full body in tricot mesh with a York double-layer dazzle material used for the sleeves and collar. The team name is screen printed at the center front, while the team logo is screen printed on each sleeve. The twill or dazzle lettering on the jerseys is customizable and the buyer's company logo can be screen printed on each sleeve and incorporated as a woven label jock tag. The jerseys come in the following sizes: Small (31"), Medium (35"), Large (40"), XL (44"), XXL (48"), XXXL (53"). Full customization options are available, including different color options and double stitching on all seams. The facility offers tackle twill, silk screen print and sublimation options, as well as the ability to print any team name and variable number. The buyer's team logo, brand logo and woven label can also be incorporated.
      
      </p>
      
      `,
      furtherObj: [
        {
          name: "American Football",
          img: "Sports Wear/American Football/AF1.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/AF2.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/AF3.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20Football20Uniforms_img_369.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20Football20Uniforms_img_370.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20Football20Uniforms_img_371.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20Football20Uniforms_img_372.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20Football20Uniforms_img_373.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20FootI-ball20Uniforms_img_374.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20Football20Uniforms_img_375.jpg",
        },
        {
          name: "American Football",
          img: "Sports Wear/American Football/American20Football20Uniforms_img_376.jpg",
        },
      ],
    },
    {
      name: "Baseball jerseys",
      img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_353.jpg",
      desc: `
      <p>
<ul>

<li>
Sleeveless Full Button Baseball Top
</li>
<li>
90% polyester / 10% lycra stretch mesh moisture management fabric</li>
<li>
White contrast taping on neck and button hems</li>
<li>
Muscle construction with double needle hem</li>
<li>
Clear-color buttons</li>
<li>
We recommend wearing a tee shirt or performance tee under the jersey</li>
</ul>
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_353.jpg",
        },
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_354.jpg",
        },
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_355.jpg",
        },
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_356.jpg",
        },
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_357.jpg",
        },
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_358.jpg",
        },
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/Baseball20Jerseys_img_359.jpg",
        },
        {
          name: "Baseball jerseys",
          img: "Sports Wear/Baseball jerseys/BJ1.jpg",
        },
      ],
    },
    {
      name: "Baseball uniform",
      img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_169.jpg",
      desc: `
      <p>
<ul>

<li>
Sleeveless Full Button Baseball Top
</li>
<li>
90% polyester / 10% lycra stretch mesh moisture management fabric</li>
<li>
White contrast taping on neck and button hems</li>
<li>
Muscle construction with double needle hem</li>
<li>
Clear-color buttons</li>
<li>
We recommend wearing a tee shirt or performance tee under the jersey</li>
<li>
BASEBALL PANTS:
<li>
Pro Style Open Bottom Baggy Cut Baseball Pant
</li>
<li>
100% Polyester 14 oz. warp-knit body</li>
<li>
New 2 way stretch warp-knit fabric</li>
<li>
Double-layer knee construction</li>
<li>
2 welted back pockets</li>
<li>
Metal zipper fly with 2 snap waist-band</li>
<li>
Full tunnel belt loops</li>
<li>
Mens inseam: S(27) M(28) L(29) XL(30) 2XL(31)</li>
<li>
One-size-fits-all elastic belt included</li>
</li>
</ul>
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_169.jpg",
        },
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_170.jpg",
        },
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_171.jpg",
        },
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_172.jpg",
        },
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_173.jpg",
        },
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_174.jpg",
        },
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/Baseball20Uniforms_img_175.jpg",
        },
        {
          name: "Baseball uniform",
          img: "Sports Wear/baseball uniform/BU1.jpg",
        },
      ],
    },
    {
      name: "Basketball",
      img: "Sports Wear/Basketball/Basketball20Uniforms_img_381.jpg",
      desc: `
      <p>The Reversible Mesh/Dazzle Top and Short ensemble is crafted from premium quality materials. The top features a 100% polyester 70 denier tricot mesh body and 100% polyester dazzle contrast fabric, offering a sleek and stylish appearance. The top features a sleeveless construction with a finished hem and an oversized Pro Cut fit for added comfort and ease of movement. The accompanying short is made from the same high-quality materials, featuring a 100% polyester 70 denier tricot mesh body and 100% polyester dazzle contrast fabric. The waistband is equipped with a covered elastic waistband and an inside drawcord for a secure fit, and the body is double thickness for added durability. The product code for this ensemble is PI-1382.
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_381.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_382.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_383.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_384.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_385.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_386.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_387.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/Basketball20Uniforms_img_388.jpg",
        },
        {
          name: "Basketball",
          img: "Sports Wear/Basketball/BB1.jpg",
        },
      ],
    },
    {
      name: "GoalKeeper",
      img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_41.jpg",
      desc: `
      <p>
<ul>

<li>
PADDED GOALIE KIT: The goalkeeper shirts and shorts with thick padded and comfortable cushion in the forearms?elbows and hip.Which offer protection when the keeper dives and saves.</li>
<li>
AIR-DRY SOCCER UNIFORM: K-AIR cool Technology, which is moisture-wicking materials could be stay cool, quick-drying and lightweight during soccer practice and matches. Keep it's shape after washing.</li>
<li>
OUTDOOR SOCCER OVERALL DESIGN: When the weather is raining or get chilly, long-sleeve soccer shirt would be the best chioce. Have it layer up with compression shirts and shorts.</li>
<li>
BRIGHT COLOR and FIT: Kelme Goalkeeper Jersey have a variety of bright color. which is attractive and makes the goalkeeper standout.</li>
<li>
PRO GOALKEEPER JERSEY: This soccer goalie shirts with elastic and double stitching in Cuffs. When you wear goalie gloves, you could bunch them up.</li>

</ul>
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_41.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_42.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_43.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_44.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_45.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_46.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_47.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_48.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_49.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/20Goalkeeper20Uniforms_img_50.jpg",
        },
        {
          name: "GoalKeeper",
          img: "Sports Wear/GoalKeeper/GK1.jpg",
        },
      ],
    },
    {
      name: "Hoodies",
      img: "Sports Wear/hoodies/Hoodies_img_124.jpg",
      desc: `
      <p>
<ul>

<li>
50% Cotton, 50% Polyester</li>
<li>
Pull On closure</li>
<li>
All Heather Sport colors are 60% cotton, 40% polyester</li>
<li>
Pill-resistant air jet yarn</li>
<li>
Double-needle stitching throughout</li>
<li>
Double-lined hood</li>

</ul>
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_124.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_125.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_126.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_127.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_128.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_129.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_345.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_346.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_347.jpg",
        },
        {
          name: "Hoodies",
          img: "Sports Wear/hoodies/Hoodies_img_348.jpg",
        },
      ],
    },
    {
      name: "Icehockey",
      img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_58.jpg",
      desc: `
      <p>
<ul>

<li>
100% Polyester</li>
<li>
Adult size, S-3XL</li>
<li>
All Heather Sport colors are 60% cotton, 40% polyester</li>
<li>
LETTERING - These jerseys have single-layer and two-layer tackle twill lettering. The letters and names are cut out of twill fabric and stitched on the jersey.</li>
<li>
FABRIC - These jerseys are made of 100% heavyweight Polyester knit fabric.</li>
<li>
Long sleeve ice hockey jersey. Perfect jersey for the 90s party, jersey party, Halloween and daily life.</li>

</ul>
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_58.jpg",
        },
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_59.jpg",
        },
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_60.jpg",
        },
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_61.jpg",
        },
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_62.jpg",
        },
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_63.jpg",
        },
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_64.jpg",
        },
        {
          name: "Icehockey",
          img: "Sports Wear/Icehockey/Ice20Hockey20Uniforms_img_65.jpg",
        },
      ],
    },
    {
      name: "Netbal",
      img: "Sports Wear/Netbal/Netball_img_74.jpg",
      desc: `
      <p>Uniforms are designed with the needs of the athlete in mind, offering both comfort and durability on the court. Our uniforms are made from high-performance materials that wick away moisture, allowing for maximum comfort and breathability during intense games. Our manufacturing process involves using state-of-the-art technology to ensure that the uniform fits perfectly and meets the highest standards of quality. Our team of experienced designers works closely with professional netball players to create uniforms that are both stylish and functional. Our uniforms are available in a wide range of sizes and colors, ensuring that there is something to suit every player's style and body shape. With a commitment to quality and customer satisfaction, our company is dedicated to providing the best netball uniforms on the market.
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Netbal",
          img: "Sports Wear/Netbal/Netball_img_74.jpg",
        },
        {
          name: "Netbal",
          img: "Sports Wear/Netbal/Netball_img_75.jpg",
        },
      ],
    },
    {
      name: "Polo T shirts",
      img: "Sports Wear/polo T shirts/Polo20Shirts_img_136.jpg",
      desc: `
      <p>
<ul>

<li>
Button closure
</li>
<li>
Machine Wash</li>
<li>
Regular fit through the chest for a relaxed, unrestricted fit with a printed neck label to maximize comfort</li>
<li>
A classic cut and soft cotton fabric make this polo a go-to for the office or the weekend/li>
<li>
Clear-color buttons</li>
<li>
Short-sleeve polo shirt in breathable, soft pique waffle knit</li>
</ul>
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Polo T shirts",
          img: "Sports Wear/polo T shirts/Polo20Shirts_img_136.jpg",
        },
        {
          name: "Polo T shirts",
          img: "Sports Wear/polo T shirts/Polo20Shirts_img_137.jpg",
        },
        {
          name: "Polo T shirts",
          img: "Sports Wear/polo T shirts/Polo20Shirts_img_138.jpg",
        },
        {
          name: "Polo T shirts",
          img: "Sports Wear/polo T shirts/Polo20Shirts_img_139.jpg",
        },
        {
          name: "Polo T shirts",
          img: "Sports Wear/polo T shirts/Polo20Shirts_img_140.jpg",
        },
        {
          name: "Polo T shirts",
          img: "Sports Wear/polo T shirts/Polo20Shirts_img_141.jpg",
        },
      ],
    },
    {
      name: "Rugby",
      img: "Sports Wear/rugby/Rugby20Uniforms_img_90.jpg",
      desc: `
      <p>
<ul>

<li>
As a leading rugby uniform manufacturing company, we take pride in providing high-quality rugby uniforms for teams and players of all levels. Our products are designed with the latest materials and technologies, providing optimum comfort and durability for the rough and tough nature of the game.
</li>
<li>
We offer a wide range of rugby uniforms, from traditional jerseys and shorts to more advanced compression gear and training wear. Our products are made from premium materials, including moisture-wicking fabrics and reinforced seams, ensuring maximum comfort and performance during even the most intense games</li>

</ul>
      </p>
      
      
      `,
      furtherObj: [
        {
          name: "Rugby",
          img: "Sports Wear/rugby/Rugby20Uniforms_img_90.jpg",
        },
        {
          name: "Rugby",
          img: "Sports Wear/rugby/Rugby20Uniforms_img_91.jpg",
        },
        {
          name: "Rugby",
          img: "Sports Wear/rugby/Rugby20Uniforms_img_92.jpg",
        },
        {
          name: "Rugby",
          img: "Sports Wear/rugby/Rugby20Uniforms_img_93.jpg",
        },
        {
          name: "Rugby",
          img: "Sports Wear/rugby/Rugby20Uniforms_img_94.jpg",
        },
        {
          name: "Rugby",
          img: "Sports Wear/rugby/Rugby20Uniforms_img_95.jpg",
        },
        {
          name: "Rugby",
          img: "Sports Wear/rugby/Rugby20Uniforms_img_96.jpg",
        },
      ],
    },
  ],
];
